/* ==========================================================================
   # Legacy Style
   ========================================================================== */


/** Header **/
#header-wrapper{
  padding: 20px;

  @include mq($from: tablet) {
    padding: 50px;
  }
}

#logo-div {
  //background: url('../img/logo@2x.jpg') no-repeat;
  //background-size: contain;
  padding: 0!important;
  //margin-bottom: 20px;
}

img.logo-img {
  height: 75px;
}

#logout-div{
  padding: 0!important;
}

#logout-div a {
  padding: 14px 20px;
}

.btn{
  font-weight: 200;
}

.btn-primary
{
  background: $primary-color;
  display: inline;
}

.btn-grey
{
  background: #9C9388;
  display: inline;

}


.btn-blue
{
  background: #4995CE;
  display: inline;
}

a.link-grey{
  color: #9C9388;
  font-weight: 200;
}

.btn-grey:hover, a.link-grey:hover{
  // color: #AF292E!important;
}

.btn-grey, .btn-primary{
  color: #ffffff;
  font-size: 12px;
  text-decoration: none;
  border-radius: 0px;
  letter-spacing: 1px;
  text-transform: uppercase;
  border: 0;
  font-weight: bold;
  padding: 12px 18px;
}

.btn-grey img , .btn-primary img{
  width: 15px;
  margin-right: 8px;
}

/** End of header **/

/** Body **/
body {
  font-family: "Myriad W08", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif!important;
  font-weight: 200;
  font-style: normal;
  color: $text-color;
  -webkit-font-smoothing: antialiased; /* Fix for webkit rendering */
  -webkit-text-size-adjust: 100%;
}

.row{
  padding: 30px 0px;
  overflow-x: hidden;
  margin: 0px;
}

a{
  text-decoration: none!important;
  color: $primary-color;
  font-weight: 100;
}

a:hover{
  color: #444;
}

.form-inline {
  width: 100%;
}

h2, .h2 {
  font-weight: 200;
}

.text-grey {
  color: #9C9388;
}

.text-primary {
  color: $primary-color!important;
}

.bg-dark {
  background: #333333!important;
}

.bg-secondary {
  background: #6F6F6F!important;
}

.bg-primary {
  background: $primary-color!important;
}

.card {
  border: 0px;
  border-radius: 0px;
}

.card-header {
  font-weight: 300;
  padding: .30rem 1.00rem;
  background-color: #F7F5F0;
}

.img-fluid {

}

.hidden {
  display: none;
}
/** End of Body **/

/** Login page **/
.login-logo
{
  font-size: 35px;
  text-align: center;
  margin-bottom: 25px;
  font-weight: 300;
}

.login-logo a{
  color: #444;
}

.login-page{
  background: url('../img/books-bg.jpg') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.login-box{
  width: 700px;
  margin: 10% auto;
  border: 1px solid $primary-color;
}

@media (max-width: 768px) {
  .login-box{
    width: 90%;
    margin-top: 20px;
  }
}

.login-box-body{
  background: #fff;
  padding: 20px;
  border-top: 0;
  color: #666;

  @include mq($from: tablet) {
    padding: 50px;
  }
}

.login-box-body button.btn.btn-primary.btn-block.btn-flat{
  width: 100px;
}

.login-box-body .form-control{
  width: 300px;
  margin: 0 auto;
  max-width: 80%;
}

.login-box-msg{
  margin-bottom: 20px;
  text-align: center;
  padding: 0 20px 20px 20px;
  font-weight: 100;
}
/** End of login page **/


/** Search bar **/
.search-bar, .breadcrumb-bar, .col {
  padding-left: 50px;
  padding-right: 50px;
}

//.search-bar {
//  background: #F7F5F0;
//}

.breadcrumb-bar {
  background: #ffffff;
}

.navbar{
  padding: 10px 0;
}

.input-group{
  padding-right: 0px;
  padding-bottom: 10px;
  padding-top: 10px;
}

.input-group-append button {
  border: 0;
  padding-left: 10px;
  padding-right: 10px;
  width: 40px;
  background: url('../img/Search.svg') center no-repeat $primary-color;
  background-size: 50%;
}

.form-control , select , .btn-dropdown, .dropdown-menu{
  border-radius: 0px!important;
  border-color: #9C9388;
  font-weight: 200;
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-border-radius: 0;
}

select.form-control, .dropdown-toggle {
  background: url('../img/arrow.svg') right center/contain no-repeat, linear-gradient(#ffffff, #ffffff) 0 0/contain;
}

.btn-dropdown{
  color: #495057;
  width: 100%;
  text-align: left;
}

.dropdown-menu {
  font-weight: 200;
  font-size: inherit;
  width: inherit;
  padding: 0px;
  margin-left: 15px;
  //white-space: nowrap;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}

.dropdown-toggle::after{
  border: none;
}

.dropdown-item {
  padding: 20px;
  border-bottom: 1px solid #9C9388;
  font-weight: 200;
  font-size: 15px;
  //white-space: nowrap;
  padding: 10px 5px 10px 60px;
  min-height: 80px;
  position: relative;
  overflow-wrap: break-word;
  white-space: normal;
  word-wrap: break-word;
}

.dropdown-item img {
  position: absolute;
  top: 5px;
  left: 5px;
}

.result-block-text h3 {
  margin-bottom: 20px;
}

.img-thumbnail {
  border: none;
}

/* end of search bar */

/* results panel */
#results-panel {
  padding: 0!important;
}

.scrollable-div {
  height: 1000px;
  overflow-y: scroll;
}

.scrollable-div::-webkit-scrollbar {
  width: .50em;
  background-color: #000000!important;
}

.scrollable-div::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 4px rgba(0,0,0,0.3);
  background-color: #000000!important;
}

.scrollable-div::-webkit-scrollbar-thumb {
  background-color: #6F6F6F;
}

#text-panel a {
  color: #333;
  font-weight: 200;
}

#text-panel a:hover {
  color: #333;
}

#image-panel img.main-image {
  margin: 0 auto;
  display: block;
  height: 930px!important;
}

#image-panel .card, #image-panel .card-body {
  height: 1000px!important;
}

.controlls a {
  font-weight : 300;
  padding: .30rem .50rem;
}

.controlls a img {
  width: 12px;
  margin: 0px 6px;
  margin-top: -3px;
}

