/* ==========================================================================
   #Pagination
   ========================================================================== */

.c-pagination {
  text-align: center;

  li {
    display: inline-block;
    opacity: .7;

    + li {
      margin-left: 10px;
    }

    a,
    a:visited {
      color: inherit;
    }

    a:hover,
    a:focus {
      color: #333;
    }

    &:hover {
      opacity: 1;
    }

    &.active {
      opacity: 1;

      a {
        font-weight: 500;
      }
    }
  }
}