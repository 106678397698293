/* ==========================================================================
   #Logo
   ========================================================================== */

$logo-vsa-image-width: 155px;
$logo-vsa-image-height: 169px;

.c-logo {
  display: block;

  &__link {
    display: inline-block;
  }

  //&__image {
  //  //.
  //}

  &__title {
    @include inuit-font-size(20px);
    display: inline-block;
    max-width: 500px;

    @include mq($from: tablet) {
      @include inuit-font-size(40px);
    }
  }

  &__sponsor{
    @include inuit-font-size(16px);
    display: block;
    margin: 5px 0 0 6px;
  }
}



/* Style variants
   ========================================================================== */

.c-logo--books {
  //.

  .c-logo__image {
    height: 75px;
  }
}


.c-logo--vsa {
  min-height: $logo-vsa-image-height;
  padding-top: $logo-vsa-image-height + 10px;
  position: relative;
  text-align: left;

  @include mq($from: tablet) {
    padding-left: $logo-vsa-image-width + 20px;
    padding-top: 0;
  }

  .c-logo__image {
    position: absolute;
    top: 0;
    left: 0;
  }
}