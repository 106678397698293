///* ========================================================================
//   #GLOBAL
//   ======================================================================== */

// The global settings file contains any project-wide variables; things that
// need to be made available to the entire codebase.

$dir-fonts: '../font';
$dir-images: '../img';


// Standardise some UI treatments.

$global-radius: 3px !default;
$global-transition: all 300ms ease-in-out !default;



// Colors
$text-color: #444 !default;
$primary-color: #AF292E !default;
$fade-colour: #9C9388 !default;


$primary-bg-color: #F7F5F0 !default;