/* ==========================================================================
   # Messages
   ========================================================================== */

.message {

  + * {
    margin-top: 1em;
  }
}


* + .message {
  margin-top: 1em;
}