/* ==========================================================================
   # Main Footer
   ========================================================================== */

.main-footer {

  &__title {
    font-size: .8em;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: .075em;
  }

  &__section {
    font-size: .75em;
  }
}