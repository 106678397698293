/* ==========================================================================
   #Flipbook
   ========================================================================== */


.c-flipbook-container {
  max-width: 600px; // 450
  width: 100%;
  margin: 0 auto;
  padding: 20px;

  @include mq($from: tablet) {
    padding: 0 20px;
  }
}

.c-flipbook-goto {
  display: none;

  input[name="go_to_number"] {
    width: 4em;
    padding: 7px;
    vertical-align: middle;
    line-height: 24px;
  }
}

.c-flipbook {
  margin: 20px auto;

  .owl-item {
    //height: 679px;

    img {
      max-height: 100%;
      width: auto;
    }
  }

  .owl-nav {
    color: #fff;
    position: relative;
  }

  .owl-next,
  .owl-prev {
    &.disabled {
      opacity: .5;
    }
  }

  .owl-next {
    position: absolute;
    right: 0;
    top: 10px;

    &:before {
      content: 'Next';
      display: inline-block;
      margin-right: 5px;
    }
  }

  .owl-prev {
    position: absolute;
    left: 0;
    top: 10px;

    &:after {
      content: 'Previous';
      display: inline-block;
      margin-left: 5px;
    }
  }

  //&__page {
  //  background: #fff;
  //
  //  img {
  //    width: 100%;
  //  }
  //
  //  &--empty {
  //    background: #fff url($dir-images + '/ajax-loader.gif') center center no-repeat;
  //  }
  //}
}