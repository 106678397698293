@charset "UTF-8";
/* ==========================================================================
   INUITCSS
   ========================================================================== */
/**
 * inuitcss, by @csswizardry
 *
 * github.com/inuitcss | inuitcss.com
 */
/**
 * This is an example inuitcss manifest file. DO NOT MODIFY THIS FILE DIRECTLY.
 * Instead, copy and paste it into your own CSS directory and make additions,
 * extensions, and modifications there.
 */
/**
 * CONTENTS
 *
 * SETTINGS
 * Config...............Project-level configuration and feature switches.
 * Core.................inuitcss’ core and setup settings.
 * Global...............Project-wide variables and settings.
 *
 * TOOLS
 * Font-size............A mixin which guarantees baseline-friendly line-heights.
 * Clearfix.............Micro clearfix mixin.
 * Hidden...............Mixin for hiding elements.
 * Sass MQ..............inuitcss’ default media query manager.
 *
 * GENERIC
 * Box-sizing...........Better default `box-sizing`.
 * Normalize.css........A level playing field using @necolas’ Normalize.css.
 * Reset................A tiny reset to complement Normalize.css.
 * Shared...............Sensibly and tersely share some global commonalities
 *                      (particularly useful when managing vertical rhythm).
 *
 * ELEMENTS
 * Page.................Set up our document’s default `font-size` and
 *                      `line-height`.
 * Headings.............Very minimal (i.e. only font-size information) for
 *                      headings 1 through 6.
 * Images...............Base image styles.
 * Tables...............Simple table styles.
 *
 * OBJECTS
 * Wrapper..............Page constraint object.
 * Layout...............Generic layout module.
 * Media................Image- and text-like content side by side. The
 *                      poster-child of OOCSS.
 * Flag.................Table-layout-based advancement on the Media object.
 * List-bare............Lists with no bullets or indents.
 * List-inline..........A list whose items all site in a line.
 * Box..................Simple boxing abstraction.
 * Block................Image-on-top-of-text object.
 * Ratio................A container for maintaining aspect ratio of content.
 * Crop.................Provide a cropping context for media (images, etc.).
 * Table................Classes for manipulating `table`s.
 * Pack.................Pack items into available horizontal space.
 *
 * COMPONENTS
 * Buttons..............An example button component, and how it fits into the
 *                      inuitcss framework.
 *
 * UTILITIES
 * Clearfix.............Bind our clearfix onto a utility class.
 * Widths...............Simple width helper classes.
 * Headings.............Reassigning our heading styles to helper classes.
 * Spacings.............Nudge bits of the DOM around with these spacing
 *                      classes.
 * Responsive-Spacings..Enhances the function of normal spacings for
 *                      responsive usage.
 * Print................Reset-like styles taken from the HTML5 Boilerplate.
 * Hide.................Helper classes to hide content
 */
/* ==========================================================================
   # Fonts
   ========================================================================== */
@font-face {
  font-family: "Myriad W08_n1";
  src: url("../font/631a1bfa-1684-4abc-a4c9-0bd744e37449.eot?#iefix") format("eot"); }

@font-face {
  font-family: "Myriad W08";
  src: url("../font/631a1bfa-1684-4abc-a4c9-0bd744e37449.eot?#iefix");
  src: url("../font/631a1bfa-1684-4abc-a4c9-0bd744e37449.eot?#iefix") format("eot"), url("../font/15d8d76f-cedb-4b89-9907-249e6a46adbb.woff") format("woff"), url("../font/bfa35483-6ce3-45f0-9e53-7653dc92487b.ttf") format("truetype"), url("../font/67dc8c32-099b-4bc1-950e-5d118a275dea.svg#67dc8c32-099b-4bc1-950e-5d118a275dea") format("svg");
  font-weight: 100;
  font-style: normal; }

@font-face {
  font-family: "Myriad W08_i1";
  src: url("../font/d83b2250-b2f1-4088-abd1-a213dd751584.eot?#iefix") format("eot"); }

@font-face {
  font-family: "Myriad W08";
  src: url("../font/d83b2250-b2f1-4088-abd1-a213dd751584.eot?#iefix");
  src: url("../font/d83b2250-b2f1-4088-abd1-a213dd751584.eot?#iefix") format("eot"), url("../font/f99d869f-ea77-4127-a22b-2c89c89b8a04.woff") format("woff"), url("../font/ce54af40-c200-4e98-b0e2-102a550a4393.ttf") format("truetype"), url("../font/cd2bbd56-59a4-40be-8b0b-9f6158b8a33d.svg#cd2bbd56-59a4-40be-8b0b-9f6158b8a33d") format("svg");
  font-weight: 100;
  font-style: italic; }

@font-face {
  font-family: "Myriad W08_n2";
  src: url("../font/76fda6fb-8617-4e88-b290-945c47127ceb.eot?#iefix") format("eot"); }

@font-face {
  font-family: "Myriad W08";
  src: url("../font/76fda6fb-8617-4e88-b290-945c47127ceb.eot?#iefix");
  src: url("../font/76fda6fb-8617-4e88-b290-945c47127ceb.eot?#iefix") format("eot"), url("../font/01932eb5-2a4e-4bd4-b8a8-8e04f69b2d66.woff") format("woff"), url("../font/b1811139-1d49-42a2-8353-e29ca087433c.ttf") format("truetype"), url("../font/77a8ed10-0737-4849-9972-26d6e7585df5.svg#77a8ed10-0737-4849-9972-26d6e7585df5") format("svg");
  font-weight: 200;
  font-style: normal; }

@font-face {
  font-family: "Myriad W08_i2";
  src: url("../font/a29d7bc9-2089-4ece-9af5-114234bcbe74.eot?#iefix") format("eot"); }

@font-face {
  font-family: "Myriad W08";
  src: url("../font/a29d7bc9-2089-4ece-9af5-114234bcbe74.eot?#iefix");
  src: url("../font/a29d7bc9-2089-4ece-9af5-114234bcbe74.eot?#iefix") format("eot"), url("../font/e24e937f-1ec2-41c0-99ac-ea86a1cfd177.woff") format("woff"), url("../font/9f03002d-4ad2-4c92-82c4-637ab2cefe28.ttf") format("truetype"), url("../font/b5bdbbde-98f3-4fb9-9b47-7da424822ad9.svg#b5bdbbde-98f3-4fb9-9b47-7da424822ad9") format("svg");
  font-weight: 200;
  font-style: italic; }

@font-face {
  font-family: "Myriad W08_n3";
  src: url("../font/44632421-aa5e-4564-95e1-9c56392b3708.eot?#iefix") format("eot"); }

@font-face {
  font-family: "Myriad W08";
  src: url("../font/44632421-aa5e-4564-95e1-9c56392b3708.eot?#iefix");
  src: url("../font/44632421-aa5e-4564-95e1-9c56392b3708.eot?#iefix") format("eot"), url("../font/4aa4296f-63c3-43a5-810a-58279e79fa8b.woff") format("woff"), url("../font/f90d27fc-fd02-4e0b-877a-4213c293ceec.ttf") format("truetype"), url("../font/d7929319-a857-472c-9acb-891f0efac51d.svg#d7929319-a857-472c-9acb-891f0efac51d") format("svg");
  font-weight: 300;
  font-style: normal; }

/* ==========================================================================
   # Legacy Style
   ========================================================================== */
/** Header **/
#header-wrapper {
  padding: 20px; }
  @media (min-width: 46.25em) {
    #header-wrapper {
      padding: 50px; } }

#logo-div {
  padding: 0 !important; }

img.logo-img {
  height: 75px; }

#logout-div {
  padding: 0 !important; }

#logout-div a {
  padding: 14px 20px; }

.btn {
  font-weight: 200; }

.btn-primary {
  background: #AF292E;
  display: inline; }

.btn-grey {
  background: #9C9388;
  display: inline; }

.btn-blue {
  background: #4995CE;
  display: inline; }

a.link-grey {
  color: #9C9388;
  font-weight: 200; }

.btn-grey, .btn-primary {
  color: #ffffff;
  font-size: 12px;
  text-decoration: none;
  border-radius: 0px;
  letter-spacing: 1px;
  text-transform: uppercase;
  border: 0;
  font-weight: bold;
  padding: 12px 18px; }

.btn-grey img, .btn-primary img {
  width: 15px;
  margin-right: 8px; }

/** End of header **/
/** Body **/
body {
  font-family: "Myriad W08", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  font-weight: 200;
  font-style: normal;
  color: #444;
  -webkit-font-smoothing: antialiased;
  /* Fix for webkit rendering */
  -webkit-text-size-adjust: 100%; }

.row {
  padding: 30px 0px;
  overflow-x: hidden;
  margin: 0px; }

a {
  text-decoration: none !important;
  color: #AF292E;
  font-weight: 100; }

a:hover {
  color: #444; }

.form-inline {
  width: 100%; }

h2, .h2 {
  font-weight: 200; }

.text-grey {
  color: #9C9388; }

.text-primary {
  color: #AF292E !important; }

.bg-dark {
  background: #333333 !important; }

.bg-secondary {
  background: #6F6F6F !important; }

.bg-primary {
  background: #AF292E !important; }

.card {
  border: 0px;
  border-radius: 0px; }

.card-header {
  font-weight: 300;
  padding: .30rem 1.00rem;
  background-color: #F7F5F0; }

.hidden {
  display: none; }

/** End of Body **/
/** Login page **/
.login-logo {
  font-size: 35px;
  text-align: center;
  margin-bottom: 25px;
  font-weight: 300; }

.login-logo a {
  color: #444; }

.login-page {
  background: url("../img/books-bg.jpg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover; }

.login-box {
  width: 700px;
  margin: 10% auto;
  border: 1px solid #AF292E; }

@media (max-width: 768px) {
  .login-box {
    width: 90%;
    margin-top: 20px; } }

.login-box-body {
  background: #fff;
  padding: 20px;
  border-top: 0;
  color: #666; }
  @media (min-width: 46.25em) {
    .login-box-body {
      padding: 50px; } }

.login-box-body button.btn.btn-primary.btn-block.btn-flat {
  width: 100px; }

.login-box-body .form-control {
  width: 300px;
  margin: 0 auto;
  max-width: 80%; }

.login-box-msg {
  margin-bottom: 20px;
  text-align: center;
  padding: 0 20px 20px 20px;
  font-weight: 100; }

/** End of login page **/
/** Search bar **/
.search-bar, .breadcrumb-bar, .col {
  padding-left: 50px;
  padding-right: 50px; }

.breadcrumb-bar {
  background: #ffffff; }

.navbar {
  padding: 10px 0; }

.input-group {
  padding-right: 0px;
  padding-bottom: 10px;
  padding-top: 10px; }

.input-group-append button {
  border: 0;
  padding-left: 10px;
  padding-right: 10px;
  width: 40px;
  background: url("../img/Search.svg") center no-repeat #AF292E;
  background-size: 50%; }

.form-control, select, .btn-dropdown, .dropdown-menu {
  border-radius: 0px !important;
  border-color: #9C9388;
  font-weight: 200;
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-border-radius: 0; }

select.form-control, .dropdown-toggle {
  background: url("../img/arrow.svg") right center/contain no-repeat, linear-gradient(#ffffff, #ffffff) 0 0/contain; }

.btn-dropdown {
  color: #495057;
  width: 100%;
  text-align: left; }

.dropdown-menu {
  font-weight: 200;
  font-size: inherit;
  width: inherit;
  padding: 0px;
  margin-left: 15px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto; }

.dropdown-toggle::after {
  border: none; }

.dropdown-item {
  padding: 20px;
  border-bottom: 1px solid #9C9388;
  font-weight: 200;
  font-size: 15px;
  padding: 10px 5px 10px 60px;
  min-height: 80px;
  position: relative;
  overflow-wrap: break-word;
  white-space: normal;
  word-wrap: break-word; }

.dropdown-item img {
  position: absolute;
  top: 5px;
  left: 5px; }

.result-block-text h3 {
  margin-bottom: 20px; }

.img-thumbnail {
  border: none; }

/* end of search bar */
/* results panel */
#results-panel {
  padding: 0 !important; }

.scrollable-div {
  height: 1000px;
  overflow-y: scroll; }

.scrollable-div::-webkit-scrollbar {
  width: .50em;
  background-color: #000000 !important; }

.scrollable-div::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
  background-color: #000000 !important; }

.scrollable-div::-webkit-scrollbar-thumb {
  background-color: #6F6F6F; }

#text-panel a {
  color: #333;
  font-weight: 200; }

#text-panel a:hover {
  color: #333; }

#image-panel img.main-image {
  margin: 0 auto;
  display: block;
  height: 930px !important; }

#image-panel .card, #image-panel .card-body {
  height: 1000px !important; }

.controlls a {
  font-weight: 300;
  padding: .30rem .50rem; }

.controlls a img {
  width: 12px;
  margin: 0px 6px;
  margin-top: -3px; }

/* ==========================================================================
   # Messages
   ========================================================================== */
.message + * {
  margin-top: 1em; }

* + .message {
  margin-top: 1em; }

/* set a min width as mobile isn't working good */
body {
  min-width: 1000px; }

/* ==========================================================================
   #Tab
   ========================================================================== */
.o-tab {
  display: none; }
  .o-tab--active {
    display: block; }

/* ==========================================================================
   #Flipbook
   ========================================================================== */
.c-flipbook-container {
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
  padding: 20px; }
  @media (min-width: 46.25em) {
    .c-flipbook-container {
      padding: 0 20px; } }

.c-flipbook-goto {
  display: none; }
  .c-flipbook-goto input[name="go_to_number"] {
    width: 4em;
    padding: 7px;
    vertical-align: middle;
    line-height: 24px; }

.c-flipbook {
  margin: 20px auto; }
  .c-flipbook .owl-item img {
    max-height: 100%;
    width: auto; }
  .c-flipbook .owl-nav {
    color: #fff;
    position: relative; }
  .c-flipbook .owl-next.disabled,
  .c-flipbook .owl-prev.disabled {
    opacity: .5; }
  .c-flipbook .owl-next {
    position: absolute;
    right: 0;
    top: 10px; }
    .c-flipbook .owl-next:before {
      content: 'Next';
      display: inline-block;
      margin-right: 5px; }
  .c-flipbook .owl-prev {
    position: absolute;
    left: 0;
    top: 10px; }
    .c-flipbook .owl-prev:after {
      content: 'Previous';
      display: inline-block;
      margin-left: 5px; }

/* ==========================================================================
   #Logo
   ========================================================================== */
.c-logo {
  display: block; }
  .c-logo__link {
    display: inline-block; }
  .c-logo__title {
    font-size: 20px;
    font-size: 1.25rem;
    line-height: 1.5;
    display: inline-block;
    max-width: 500px; }
    @media (min-width: 46.25em) {
      .c-logo__title {
        font-size: 40px;
        font-size: 2.5rem;
        line-height: 1.2; } }
  .c-logo__sponsor {
    font-size: 16px;
    font-size: 1rem;
    line-height: 1.5;
    display: block;
    margin: 5px 0 0 6px; }

/* Style variants
   ========================================================================== */
.c-logo--books .c-logo__image {
  height: 75px; }

.c-logo--vsa {
  min-height: 169px;
  padding-top: 179px;
  position: relative;
  text-align: left; }
  @media (min-width: 46.25em) {
    .c-logo--vsa {
      padding-left: 175px;
      padding-top: 0; } }
  .c-logo--vsa .c-logo__image {
    position: absolute;
    top: 0;
    left: 0; }

/* ==========================================================================
   #Pagination
   ========================================================================== */
.c-pagination {
  text-align: center; }
  .c-pagination li {
    display: inline-block;
    opacity: .7; }
    .c-pagination li + li {
      margin-left: 10px; }
    .c-pagination li a,
    .c-pagination li a:visited {
      color: inherit; }
    .c-pagination li a:hover,
    .c-pagination li a:focus {
      color: #333; }
    .c-pagination li:hover {
      opacity: 1; }
    .c-pagination li.active {
      opacity: 1; }
      .c-pagination li.active a {
        font-weight: 500; }

/* ==========================================================================
   # Main Footer
   ========================================================================== */
.main-footer__title {
  font-size: .8em;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: .075em; }

.main-footer__section {
  font-size: .75em; }

/* ==========================================================================
   # Colors
   ========================================================================== */
.u-bg-primary {
  background: #F7F5F0; }
