/* ==========================================================================
   # Fonts
   ========================================================================== */

@font-face{
  font-family:"Myriad W08_n1";
  src:url($dir-fonts + "/631a1bfa-1684-4abc-a4c9-0bd744e37449.eot?#iefix") format("eot")
}
@font-face{
  font-family:"Myriad W08";
  src:url($dir-fonts + "/631a1bfa-1684-4abc-a4c9-0bd744e37449.eot?#iefix");
  src:url($dir-fonts + "/631a1bfa-1684-4abc-a4c9-0bd744e37449.eot?#iefix") format("eot"),url($dir-fonts + "/15d8d76f-cedb-4b89-9907-249e6a46adbb.woff") format("woff"),url($dir-fonts + "/bfa35483-6ce3-45f0-9e53-7653dc92487b.ttf") format("truetype"),url($dir-fonts + "/67dc8c32-099b-4bc1-950e-5d118a275dea.svg#67dc8c32-099b-4bc1-950e-5d118a275dea") format("svg");
  font-weight: 100;
  font-style: normal;
}
@font-face{
  font-family:"Myriad W08_i1";
  src:url($dir-fonts + "/d83b2250-b2f1-4088-abd1-a213dd751584.eot?#iefix") format("eot")
}
@font-face{
  font-family:"Myriad W08";
  src:url($dir-fonts + "/d83b2250-b2f1-4088-abd1-a213dd751584.eot?#iefix");
  src:url($dir-fonts + "/d83b2250-b2f1-4088-abd1-a213dd751584.eot?#iefix") format("eot"),url($dir-fonts + "/f99d869f-ea77-4127-a22b-2c89c89b8a04.woff") format("woff"),url($dir-fonts + "/ce54af40-c200-4e98-b0e2-102a550a4393.ttf") format("truetype"),url($dir-fonts + "/cd2bbd56-59a4-40be-8b0b-9f6158b8a33d.svg#cd2bbd56-59a4-40be-8b0b-9f6158b8a33d") format("svg");
  font-weight: 100;
  font-style: italic;
}
@font-face{
  font-family:"Myriad W08_n2";
  src:url($dir-fonts + "/76fda6fb-8617-4e88-b290-945c47127ceb.eot?#iefix") format("eot")
}
@font-face{
  font-family:"Myriad W08";
  src:url($dir-fonts + "/76fda6fb-8617-4e88-b290-945c47127ceb.eot?#iefix");
  src:url($dir-fonts + "/76fda6fb-8617-4e88-b290-945c47127ceb.eot?#iefix") format("eot"),url($dir-fonts + "/01932eb5-2a4e-4bd4-b8a8-8e04f69b2d66.woff") format("woff"),url($dir-fonts + "/b1811139-1d49-42a2-8353-e29ca087433c.ttf") format("truetype"),url($dir-fonts + "/77a8ed10-0737-4849-9972-26d6e7585df5.svg#77a8ed10-0737-4849-9972-26d6e7585df5") format("svg");
  font-weight: 200;
  font-style: normal;
}
@font-face{
  font-family:"Myriad W08_i2";
  src:url($dir-fonts + "/a29d7bc9-2089-4ece-9af5-114234bcbe74.eot?#iefix") format("eot")
}
@font-face{
  font-family:"Myriad W08";
  src:url($dir-fonts + "/a29d7bc9-2089-4ece-9af5-114234bcbe74.eot?#iefix");
  src:url($dir-fonts + "/a29d7bc9-2089-4ece-9af5-114234bcbe74.eot?#iefix") format("eot"),url($dir-fonts + "/e24e937f-1ec2-41c0-99ac-ea86a1cfd177.woff") format("woff"),url($dir-fonts + "/9f03002d-4ad2-4c92-82c4-637ab2cefe28.ttf") format("truetype"),url($dir-fonts + "/b5bdbbde-98f3-4fb9-9b47-7da424822ad9.svg#b5bdbbde-98f3-4fb9-9b47-7da424822ad9") format("svg");
  font-weight: 200;
  font-style: italic;
}
@font-face{
  font-family:"Myriad W08_n3";
  src:url($dir-fonts + "/44632421-aa5e-4564-95e1-9c56392b3708.eot?#iefix") format("eot")
}
@font-face{
  font-family:"Myriad W08";
  src:url($dir-fonts + "/44632421-aa5e-4564-95e1-9c56392b3708.eot?#iefix");
  src:url($dir-fonts + "/44632421-aa5e-4564-95e1-9c56392b3708.eot?#iefix") format("eot"),url($dir-fonts + "/4aa4296f-63c3-43a5-810a-58279e79fa8b.woff") format("woff"),url($dir-fonts + "/f90d27fc-fd02-4e0b-877a-4213c293ceec.ttf") format("truetype"),url($dir-fonts + "/d7929319-a857-472c-9acb-891f0efac51d.svg#d7929319-a857-472c-9acb-891f0efac51d") format("svg");
  font-weight: 300;
  font-style: normal;
}