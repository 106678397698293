/* ==========================================================================
   #Tab
   ========================================================================== */

.o-tab {
  display: none;

  &--active {
    display: block;
  }
}